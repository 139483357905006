// import 'bootstrap/dist/css/bootstrap.min.css';
import './bootstrap.css'
import './App.css';

import { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useTranslation } from "react-i18next";
//import Layout from './components/public/layout/Layout';

const Dashboard = lazy(() => import('./components/admin/Layout/Dashboard'));
const HomePage = lazy(() => import('./components/public/Home/HomePage'));
const SignIn = lazy(() => import('./components/SignIn/SignIn'));
const Register = lazy(() => import('./components/Register'));


function App() {

  const { i18n } = useTranslation();

  return (
    <div dir={i18n.language === "en" ? "ltr" : "rtl"}>
      <Suspense fallback={<div>Loading ...</div>} >
        <Router>
          <Switch>


            <Route path="/login" component={SignIn} />
            <Route path="/register" component={Register} />
            <Route path="/admin" component={Dashboard} />
            <Route path="/" component={HomePage} />

          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
